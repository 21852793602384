import type { QueryResult } from "react-query";
import { useQuery } from "react-query";
import type { AxiosResponse } from "axios";
import axios from "axios";
import type { CurrentUser } from "types/model/user";

const getCurrentUser = async (): Promise<CurrentUser> => {
  const response: AxiosResponse<CurrentUser> = await axios.get("/api/user");
  return response.data;
};

export default function useCurrentUser(): QueryResult<CurrentUser> {
  return useQuery(["currentUser"], getCurrentUser);
}
