import type { Spotlight } from "helpers/onboarding";
import { createContext, useContext, useState } from "react";
import type { DateRangeStringifiedValues } from "types/date";
import type { FieldFilters } from "types/model/field-data";

interface SharedState {
  bookingsFilters: FieldFilters;
  bookingsDateRange: DateRangeStringifiedValues;
  activitiesFilters: FieldFilters;
  activitiesDateRange: DateRangeStringifiedValues;
  attendeesFilters: FieldFilters;
  usersFilters: FieldFilters;
  sessionsBookedReportFilters: FieldFilters;
  sessionsBookedReportDateRange: DateRangeStringifiedValues;
  activitySalesReportFilters: FieldFilters;
  activitySalesReportDateRange: DateRangeStringifiedValues;
  attendanceReportFilters: FieldFilters;
  attendanceReportDateRange: DateRangeStringifiedValues;
  userSubscriptionsFilters: FieldFilters;
  userSubscriptionsActivityFilters: FieldFilters;
  spotlight: Spotlight | null;
  isSidebarOpen: boolean;
  isGetStartedDialogOpen: boolean;
}

interface AppContextInterface {
  sharedState: SharedState;
  updateSharedState: (name: string, value: unknown) => void;
}

const initialState: SharedState = {
  bookingsFilters: {},
  bookingsDateRange: {},
  activitiesFilters: {},
  activitiesDateRange: {},
  attendeesFilters: {},
  usersFilters: {},
  sessionsBookedReportFilters: {},
  sessionsBookedReportDateRange: {},
  activitySalesReportFilters: {},
  activitySalesReportDateRange: {},
  attendanceReportFilters: {},
  attendanceReportDateRange: {},
  userSubscriptionsFilters: {},
  userSubscriptionsActivityFilters: {},
  spotlight: null,
  isSidebarOpen: false,
  isGetStartedDialogOpen: false
};

const AppContext = createContext<AppContextInterface>({
  sharedState: initialState,
  updateSharedState: () => {
    return;
  }
});

export function AppWrapper({ children }) {
  const [sharedState, setSharedState] = useState(initialState);

  const updateSharedState = (name: string, value: unknown) => {
    setSharedState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <AppContext.Provider value={{ sharedState, updateSharedState }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
